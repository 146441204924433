import { Injectable, inject } from '@angular/core';
import { HttpBackend, HttpClient, HttpEventType, HttpUploadProgressEvent } from '@angular/common/http';
import { filter, map, switchMap, Observable } from 'rxjs';
import { environmentToken } from '../environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private http = inject(HttpClient);
  private env = inject(environmentToken);
  private httpWithoutInterceptors = new HttpClient(inject(HttpBackend));

  get<T = any>(path: string, options?: object): Observable<T> {
    return this.http.get<T>(`${this.env.apiUrl}${path}`, options);
  }

  put<T = any>(path: string, body: any = {}): Observable<any> {
    return this.http.put<T>(`${this.env.apiUrl}${path}`, JSON.stringify(body)).pipe(
      map(data => data ?? true), // return true if we get succeeded empty content
    );
  }

  post<T = any>(path: string, body: any = {}): Observable<T> {
    return this.http.post<T>(`${this.env.apiUrl}${path}`, JSON.stringify(body)).pipe(
      map(data => (data ?? true) as T), // return true if we get succeeded empty content
    );
  }

  postFormData(path: string, body: any): Observable<any> {
    return this.http.post(`${this.env.apiUrl}${path}`, body).pipe(
      map(data => data ?? true), // return true if we get succeeded empty content
    );
  }

  patch(path: string, body: any = {}): Observable<any> {
    return this.http.patch(`${this.env.apiUrl}${path}`, JSON.stringify(body)).pipe(
      map(data => data ?? true), // return true if we get succeeded empty content
    );
  }

  delete(path: string, options: any = {}): Observable<any> {
    return this.http.delete(`${this.env.apiUrl}${path}`, { ...options }).pipe(
      map(data => data ?? true), // return true if we get succeeded empty content
    );
  }

  uploadFile(url: string, formData: any): Observable<any> {
    return this.http
      .post(url, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        filter((e): e is HttpUploadProgressEvent => e.type === HttpEventType.UploadProgress),
        map(event => ({ percent: Math.round((100 * event.loaded) / (event.total || 1)) })),
      );
  }

  request(method: string, path: string, data?: any): Observable<any> {
    return this.http
      .request(method, `${this.env.apiUrl}${path}`, {
        body: data ? data : null,
      })
      .pipe(
        map((response: any) => (response === null ? true : response)), // return true if we get succeeded empty content
      );
  }

  loadFile(path: string): Observable<any> {
    return this.get<string>(path, { responseType: 'text' }).pipe(
      switchMap(uri => {
        if (uri.startsWith('http')) {
          return this.httpWithoutInterceptors.get(uri, { observe: 'response', responseType: 'blob' }).pipe(map(r => r.body));
        }
        return this.get<Blob>(uri, { responseType: 'blob' });
      }),
    );
  }
}

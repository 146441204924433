import { set as setDateFrom, intervalToDuration, formatISO, compareAsc } from 'date-fns';
import { differenceInDays } from 'date-fns/fp';

export const dayHourDiffStr = (d1: Date, d2: Date, advancedTimeView?: boolean): string => {
  const isAscendig = compareAsc(d1, d2) < 1;
  const start = isAscendig ? d1 : d2;
  const end = isAscendig ? d2 : d1;
  const duration = intervalToDuration({ start, end });
  if (advancedTimeView) {
    return duration.years
      ? `${duration.years}y, ${duration.months ?? 0}m-th, ${duration.days ?? 0}d`
      : duration.months
        ? `${duration.months}m-th, ${duration.days ?? 0}d, ${duration.hours ?? 0}h`
        : `${duration.days ?? 0}d, ${duration.hours ?? 0}h, ${duration.minutes ?? 0}m`;
  }
  const daysDiff = differenceInDays(start, end);
  return `${daysDiff}/${duration.hours ?? 0}`;
};

interface ISOFormatter {
  (date: Date | string): string;
  <T>(date: T): T;
}
export const dateAsYYYYMMDD: ISOFormatter = (date: any) => {
  if (!(date instanceof Date)) {
    return date;
  }
  return formatISO(date, { representation: 'date' });
};

interface DateParams {
  baseDate?: Date;
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  min?: number;
  sec?: number;
  ms?: number;
}

/**
 * remember that native month index starts from 0
 */
export const getDateAt = ({ baseDate = new Date(), year, month, day, hour, min, sec, ms }: DateParams): Date =>
  setDateFrom(baseDate, { year, month, date: day, hours: hour, minutes: min, seconds: sec, milliseconds: ms });

/**
 * read date parts as UTC to display correctly at local time
 */
export const getDateAtUTC = ({ baseDate = new Date(), year, month, day, hour, min, sec, ms }: DateParams) => {
  const v = baseDate;
  return new Date(
    year ?? v.getUTCFullYear(),
    month ?? v.getUTCMonth(),
    day ?? v.getUTCDate(),
    hour ?? v.getUTCHours(),
    min ?? v.getUTCMinutes(),
    sec ?? v.getUTCSeconds(),
    ms ?? v.getUTCMilliseconds(),
  );
};

// converts to UTC date, but with local timezone
export const localDateAtUtc = (date: Date) =>
  getDateAt({
    baseDate: new Date(),
    hour: 0,
    min: 0,
    sec: 0,
    ms: 0,
    day: date.getUTCDate(),
    month: date.getUTCMonth(),
    year: date.getUTCFullYear(),
  });

/**
 * get UTC date
 */
export const getUTCAt = ({ baseDate = new Date(), year, month, day, hour, min, sec, ms }: DateParams) => {
  const v = baseDate;
  const utc = Date.UTC(
    year ?? v.getFullYear(),
    month ?? v.getMonth(),
    day ?? v.getDate(),
    hour ?? v.getHours(),
    min ?? v.getMinutes(),
    sec ?? v.getSeconds(),
    ms ?? v.getMilliseconds(),
  );
  return new Date(utc);
};

export const nowUTC = () => getDateAtUTC({});

export const WORK_DAYS_IN_MONTH = 21;

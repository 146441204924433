import { InjectionToken } from '@angular/core';

export interface Environment {
  production: boolean;
  apiUrl: string;
  apiRefUrl: string;
  mcaUploadUrl: string;
  websocketUrl: string;
  fundingVenues: number[];
  rtrProfitCashOnCashValues: {
    minValue: number;
    maxValue: number;
    periodInMonth: number;
  };
  defaultCapitalWithCommValues: {
    minValue: number;
    maxValue: number;
  };
  cognitoConfig: {
    userPoolId: string;
    userPoolClientId: string;
  };
}

export const environmentToken = new InjectionToken<Environment>('environment');

/* eslint-disable @typescript-eslint/naming-convention */
import { animate, group, state, style, transition, trigger } from '@angular/animations';

// unused
export const toastAnimation = trigger('flyInOut', [
  state(
    'inactive',
    style({
      opacity: 0,
    }),
  ),
  state('active', style({})),
  state('removed', style({ opacity: 0 })),
  transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
  transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
]);

// unused
export const slideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible',
      }),
    ),
    state(
      'out',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden',
      }),
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0',
          }),
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px',
          }),
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden',
          }),
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible',
          }),
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '500px',
          }),
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1',
          }),
        ),
      ]),
    ]),
  ]),
];

export const zoomInOutAnimation = [
  trigger('zoomInOut', [
    state(
      'in',
      style({
        transform: 'scale(1)',
        opacity: '1',
        visibility: 'visible',
      }),
    ),
    state(
      'out',
      style({
        opacity: '1',
        transform: 'scale(1.5)',
        visibility: 'hidden',
      }),
    ),
    transition('in => out', [
      group([
        animate(
          '0ms ease-in-out',
          style({
            transform: 'scale(1)',
          }),
        ),
        animate(
          '200ms ease-in-out',
          style({
            transform: 'scale(0.5)',
          }),
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '0ms ease-in-out',
          style({
            visibility: 'visible',
            transform: 'scale(1.5)',
          }),
        ),
        animate(
          '200ms ease-in-out',
          style({
            transform: 'scale(1)',
          }),
        ),
      ]),
    ]),
  ]),
];

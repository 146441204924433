import { ControlValueAccessor } from '@angular/forms';

import { Constructor } from './constructor';

/**
 * Adds placeholders for ControlValueAccessor methods
 */
export const mixinControlValueAccessor = <T extends Constructor<any>>(base: T): Constructor<ControlValueAccessor> & T =>
  class extends base implements ControlValueAccessor {
    writeValue() {}
    protected onChange = () => {};
    protected onTouched = () => {};

    registerOnChange(fn: any) {
      this.onChange = fn;
    }

    registerOnTouched(fn: any) {
      this.onTouched = fn;
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };

import { inject, Injectable } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';

/**
 * CSV part from:
 * https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643
 */
@Injectable({
  providedIn: 'root',
})
export class ExportService {
  private fileSaverService = inject(FileSaverService);

  public exportToCsv(rows: any[], fileName: string, header?: string[], allowedKeys?: string[]): void {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = allowedKeys || Object.keys(rows[0]);
    const csvContent =
      (header || keys).join(separator) +
      '\n' +
      rows
        .map(row =>
          keys
            .map(k => {
              const cell = row[k] === null || row[k] === undefined ? '' : row[k];
              return this.formatCell(cell);
            })
            .join(separator),
        )
        .join('\n');
    this.saveAsFile(csvContent, fileName, 'text/csv;charset=utf-8');
  }

  public exportArrayDataToCsv(data: string[][], headers: string[], fileName: string) {
    const csvRows = [headers.join(',')];

    data.forEach(row => {
      const csvRow = row.map(cell => this.formatCell(cell)).join(',');
      csvRows.push(csvRow);
    });

    const csvContent = csvRows.join('\n');

    this.saveAsFile(csvContent, fileName, 'text/csv;charset=utf-8');
  }

  formatCell(value: any) {
    let cell = value instanceof Date ? value.toLocaleString() : value.toString().replace(/"/g, '""');
    if (cell.search(/("|,|\n)/g) >= 0) {
      cell = `"${cell}"`;
    }
    return cell;
  }

  saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const BOM = '\uFEFF';
    const csvContent = BOM + buffer;
    const data: Blob = new Blob([csvContent], { type: fileType });
    this.fileSaverService.save(data, fileName);
  }
}

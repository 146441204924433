import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, catchError, EMPTY, filter, first, map, Observable, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LazyService {
  private destroyRef = inject(DestroyRef);

  private cache$ = new BehaviorSubject<Record<string, any>>({});
  private loaders: Record<string, () => Observable<any>> = {};

  updateCache<T>(cacheName: string, loader?: () => Observable<T>) {
    this.loaders[cacheName] = loader ?? this.loaders[cacheName];
    this.loaders[cacheName]()
      .pipe(
        catchError(() => EMPTY),
        startWith(null),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(value => {
        this.cache$.next({ ...this.cache$.value, [cacheName]: value });
      });
  }

  loadOnce<T>(cacheName: string, loader?: () => Observable<T>) {
    const cachedValue = this.cache$.getValue()[cacheName];
    const cachedLoader = loader ?? this.loaders[cacheName];
    if (cachedValue === undefined && cachedLoader) {
      this.updateCache<T>(cacheName, cachedLoader);
    }
    return this.cache$.pipe(
      map(cache => cache[cacheName]),
      filter<T>(v => v !== null),
      first(),
    );
  }

  deleteCache(cacheName: string) {
    this.cache$.next({ ...this.cache$.value, [cacheName]: undefined });
    delete this.loaders[cacheName];
  }

  getCache<T>(cacheName: string): T {
    return this.cache$.getValue()[cacheName];
  }
}
